.loginPanelWrap{
    z-index: 120;
    background: rgba(0, 0, 0, 0.0);
    color: rgba(0, 0, 0, 0.80);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.28s linear;
    
    .loginPanel{
        width: 800px;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-70%,0);
        transition: all 0.25s linear;
        border-radius: 10px;
        background-color: #fff;
        
        .backBtn{
            position: absolute;
            top: 14px;
            left: 20px;
            cursor: pointer;
            width: 80px;
            height: 54px;
            display: flex;
            align-items: center;
            justify-content: center;
            i{
                font-size: 32px;
            }
            span{
                font-size: 20px;
                font-weight: 500;
            }
        }
        .closeBtn{
            position: absolute;
            top: 14px;
            right: 14px;
            cursor: pointer;
            width: 54px;
            height: 54px;
            display: flex;
            align-items: center;
            justify-content: center;
            i{
                font-size: 32px;
            }
        }
        .loyaltyTipCon{
            height: 0;
            transition: all 0.3s;
            overflow: hidden;
            padding-top: 72px;
            margin-bottom: -72px;
            .loyaltyTip{
                display: flex;
            	justify-content: center;
            	padding: 16px;
            	line-height: 22px;
            	font-weight: 500;
            	font-size: 16px;
            	background-color: #120136;
            	color: #fff;
            	img{
            		width: 24px;
            		height: 24px;
            		margin-right: 4px;
            	}
            }
        }
        
        .loginPanelContainer{
            width: 100%;
            min-height: 480px;
            padding: 53px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .loginBtn{
                margin-bottom: 16px;
            }
            
            .loginPanelContent{
                width: 100%;
                padding: 0 65px;
                .title{
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 8px;
                }
                .subTitle{
                    font-size: 14px;
                    font-weight: 400;
                    span{
                        font-weight: 600;
                        padding-left: 2px;
                    }
                }
                .loginTypes{
                    padding: 32px 0;
                    display: flex;
                    .enterCon{
                        flex: 1;
                        .phoneOrEmail{
                            width: 100%;
                            overflow: hidden;
                            position: relative;
                            .phoneAndEmail{
                                width: 200%;
                                display: flex;
                                transition: all 0.3s;
                                >.ui_input_con {
                                    flex: 1;
                                }
                            }
                            &.showRight{
                                .phoneAndEmail{
                                    margin-left: -100%;
                                }
                            }
                        }
                    }
                    .midGap{
                        width: 21px;
                        position: relative;
                        margin-left: 29px;
                        margin-right: 29px;
                        .bgLine{
                            background-color: rgba(0,0,0,0.2);
                            width: 1px;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 10px;
                        }
                        .or{
                            color: var(--neutral-colors-g-350, rgba(0, 0, 0, 0.50));
                            text-align: center;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 32px;
                            background-color: #fff;
                            width: 18px;
                            height: 32px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate3d(-50%,-50%,0);
                        }
                    }
                    .otherTypes{
                        flex: 1;
                        .otherType{
                            border-radius: 10px;
                            background: var(--neutral-colors-c-93, rgba(0, 0, 0, 0.03));
                            height: 44px;
                            margin-bottom: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            span{
                                padding-left: 8px;
                                font-size: 16px;
                                font-weight: 600;
                            }
                        }
                    }
                }
                .signupTip{
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 32px;
                    span{
                        color: var(--functional-colors-blue-t-1100, #04F);
                        font-weight: 700;
                        padding-left: 4px;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
                .agreeTip{
                    color: var(--neutral-colors-g-270, rgba(0, 0, 0, 0.70));
                    font-size: 12px;
                    line-height: 16px;
                    a{
                        color: var(--theme-color,rgba(248,154,84,0));
                    }                    
                    .agreeTipInner{
                        padding: 10px 0;
                        display: flex;
                        i{
                            font-size: 18px;
                            margin-top: 5px;
                            cursor: pointer;
                            color: rgba(0, 0, 0, 0.70);
                            &.checked{
                                color: rgba(0, 0, 0, 0.70);
                            }
                        }
                        .checkboxTip{
                            flex: 1;
                            padding-left: 10px;
                            cursor: default;
                            p{
                                margin: 0 0 5px 0;
                            }
                            a{
                                color: var(--theme-text-color);
                            }
                        }
                    }
                }
                .onlyPhoneOut{
                    display: flex;
                    justify-content: center;
                    margin-bottom: 32px;
                    .onlyPhoneCon{
                        max-width: 342px;
                        padding: 30px 0;
                        .title{
                            margin-bottom: 15px;
                        }
                        .subTitle{
                            margin-bottom: 20px;
                            color: #454545;
                        }
                    }
                }
            }
            .verifyInputBx{
              padding-top: 55px;
              position: relative;
              max-width: 224px;
              margin: 32px 0 16px;
              z-index: 9;
              
              .verifyInputFaker{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 55px;
                display: flex;
                justify-content: space-between;
                .verifyInput {
                  width: 50px;
                  height: 55px;
                  border-radius: 5px;
                  background-color: #F2F2F2;
                  text-align: center;
                  line-height: 51px;
                  font-size: 20px;
                  position: relative;
                  border: 2px solid #F2F2F2;
                  font-weight: 600;
                  &.active{
                    border-color: #333333;
                  }
                  &.beat::after {
                    position: absolute;
                    left: 18px;
                    top: -2px;
                    content: "|";
                    color: #FFB600;
                    animation: beatAni 1s infinite;
                  }
                  @keyframes beatAni {
                    0% {
                      opacity:0;
                    }
                    50%{
                      opacity:1;
                    }
                    100%{
                      opacity: 0;
                    }
                  }
                }
                &.validCodeError{
                  .verifyInput{
                    border-color: #F44336;
                    background-color: rgba(244, 67, 54, 0.04);
                  }
                }
                
              }
              .verifyInputAll {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                height: 55px;
                width: 100%;
                opacity: 0;
                z-index: 10;
              }
              .verifyInput input::-webkit-inner-spin-button,
              .verifyInput input::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
            
            .verifyErrorText{
              padding-top: 8px;
              font-size: 14px;
              color: rgba(244, 67, 54, 1);
            }
            
            .sendTip{
                line-height: 22px;
                padding-top: 4px;
                font-size: 16px;
                b{
                    font-weight: 600;
                }
                span{
                    font-weight: 700;
                    padding-left: 4px;
                    color: #0044FF;
                    cursor: pointer;
                    text-decoration: underline;
                    &.disabled{
                        color: #898989;
                        cursor: not-allowed;
                        text-decoration: none;
                    }
                }
            }
        
            .enterCodePasswordContent{
                width: 90%;
                max-width: 342px;
                padding: 0;
                .opacityInput{
                    display: block;
                    padding: 0;
                    margin: 0;
                    outline: 0;
                    border: none;
                    height: 0;
                    opacity: 0;
                }
                .passwordError{
                    margin-bottom: 16px;
                    .passwordErrorTip{
                        padding: 13px 12px;
                        border-radius: 4px;
                        background: #FEECEB;
                        color: #C3362B;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 22px; 
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg{
                            margin-right: 8px;
                            width: 24px;
                            height: 24px;
                        }
                        span{
                            padding-right: 5px;
                            flex: 1;
                        }
                    }
                }
                .title{
                    margin-bottom: 32px;
                }
                .forgetTip{
                    font-size: 16px;
                    cursor: pointer;
                    margin-bottom: 32px;
                    text-decoration: underline;
                }
                .sendTip{
                    padding: 16px 0 32px;
                }
            }
                    
            .setPasswordContent,.forgetPasswordContent{
                width: 90%;
                max-width: 342px;
                padding: 0;
                .title{
                    margin-bottom: 32px;
                }
                .subTitle{
                    margin-bottom: 32px;
                    font-size: 16px;
                    line-height: 22px; 
                }
                .pwdErrors{
                    .errorItem{
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;
                        span{
                            font-size: 14px;
                            line-height: 20px; 
                        }
                        i{
                        	font-size: 16px;
                        	margin-right: 8px;
                        	color: rgba(244, 67, 54, 1);
                            &.success{
                            	color: rgba(105, 195, 63, 1);
                            }
                        }
                    }
                }
                .loginBtn{
                    margin-top: 32px;
                    margin-bottom: 0;
                }
                
                .submitBtn{
                    padding-top: 32px;
                }
                .sendToEmailIcon{
                    text-align: center;
                    padding-top: 18px;
                    img{
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        
            .panelJoinMember{
				padding: 0;
				display: flex;
				justify-content: center;
				.btns{
					width: 100%;
					max-width: 380px;
					button:nth-child(1){
						margin-bottom: 20px;
					}
				}
                .joinMemberWelcome{
					width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
					.desc{
						font-size: 20px;
						color: #999;
					}
					.memberPic{
						width: 180px;
						margin: 30px auto 40px;
					}
                }
                .joinMemberBirthday{
					width: 100%;
					max-width: 380px;
					.title{
						display: flex;
						align-items: center;
						img{
							width: 26px;
							margin-left: 12px;
							margin-top: 3px;
						}
					}
                    .desc{
                        margin-bottom: 40px;
						font-size: 16px;
						color: #666;
                    }
                }
                .joinMemberSuccess{
                    .title{
                        text-align: center;
                        font-size: 30px;
                    }
                    >img{
                        display: block;
                        width: 120px;
                        margin: 30px auto 30px;
                    }
                    .desc{
                        margin-bottom: 40px;
                        font-size: 16px;
                        color: #666;
                    }
                }
            }
        }
        .copyright{
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                color: var(--neutral-colors-g-520, rgba(0, 0, 0, 0.20));
                font-size: 12px;
                padding-right: 4px;
                padding-bottom: 2px;
            }
            img{
                height: 17px;
            }
        }
    }
    
    .verifiedTipCon{
        width: 86%;
        max-width: 390px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        transition: all 0.25s linear;
        border-radius: 10px;
        background-color: #fff;
        padding: 24px;
        color: rgba(0, 0, 0, 0.80);
        .title{
            font-size: 24px;
            font-weight: 600;
            display: flex;
            align-items: center;
            span{
                padding-left: 6px;
            }
        }
        .desc{
            font-size: 14px;
            line-height: 20px; 
            padding: 24px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.10);
            margin-bottom: 32px;
            span{
                color: #0772f3;
                cursor: pointer;
                text-decoration: underline;
                margin: 0 2px;
            }
        }
        .okBtn{
            
        }
    }
    
    .inAppBrowserTipWrap{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.7);
        z-index: 130;
        .inAppBrowserTip{
            .title{
                i{
                    font-size: 28px;
                    margin-right: 8px;
                    color: #ff9700;
                }
            }
            .desc{
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    
    &.show{
    	background-color: rgba(0,0,0,0.7);
    	.loginPanel{
    		opacity: 1;
            transform: translate3d(-50%,-50%,0);
            &.mobileHalf{
                transform: translate3d(-50%,0,0);
            }
    	}
    }
    &.hide{
    	background-color: rgba(0,0,0,0);
    	.loginPanel{
    		opacity: 0;
    	}
        .verifiedTipCon{
            opacity: 0;
        }
    }
}

@media screen and (min-width:800px){
    .loginPanelWrap{
        .loginPanel .loginPanelContainer .enterCodePasswordContent .passwordError{
            position: absolute;
            top: -70px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            .passwordErrorTip{
                max-width: 500px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .loginPanelWrap{
        .loginPanel{
            width: 100%;
            height: 100%;
            max-height: 100%;
            border-radius: 0;
            transform: translate3d(-50%,20%,0);
            &.mobileHalf{
                height: 70%;
                min-height: 615px;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                top: unset;
                bottom: 0;
                transform: translate3d(-50%,100%,0);
            }
            .backBtn{
                top: 6px;
                left: 6px;
                right: auto;
                right: unset;
                width: 54px;
                height: 54px;
            }
            
            .closeBtn{
                top: 6px;
                left: 6px;
                right: auto;
                right: unset;
            }
            
            .loyaltyTipCon{
                padding-top: 66px;
            }
            
            .loginPanelContainer{
                padding: 60px 0 16px;
                display: block;
                height: 100%;
                .loginPanelContent{
                    padding: 0 24px;
                    .title{
                        letter-spacing: -0.03em;
                    }
                    .loginTypes{
                        flex-direction: column;
                        padding-bottom: 24px;
                        .midGap{
                           width: 100%;
                           height: 20px;
                           margin: 32px 0;
                           .bgLine{
                               top: 10px;
                               left: 0;
                               right: 0;
                               width: 100%;
                               height: 1px;
                               bottom: auto;
                               bottom: unset;
                           }
                           .or{
                               width: 62px;
                               text-align: center;
                           }
                        }
                        .otherTypes{
                            display: flex;
                            justify-content: space-around;
                            .otherType{
                                border-radius: 24px;
                                background: var(--neutral-colors-g-85, rgba(0, 0, 0, 0.05));
                                width: 48px;
                                height: 48px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 0;
                                span{
                                    display: none;
                                }
                            }
                        }
                    }
                
                    .signupTip{
                        font-size: 16px;
                        font-weight: 500;
                        text-align: center;
                    }
                }
            
                .enterCodePasswordContent,.setPasswordContent,.forgetPasswordContent{
                    max-width: 100%;
                    width: 100%;
                }
                
            }
        }
        &.hide{
            .loginPanel{
            	opacity: 0;
                transform: translate3d(-50%,20%,0);
            }
        }
    }
}

@media screen and (max-width: 480px){
    .loginPanelWrap{
        .verifiedTipCon {
            width: 100%;
            max-width: 100%;
            min-height: 240px;
            bottom: 0;
            left: 0;
            right: 0;
            top: auto;
            top: unset;
            transform: translate3d(0,0,0);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}