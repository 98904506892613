.crmDescription{
    background-color: #120136;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 12px 15px 12px 12px;
    margin-top: 66px;
    margin-bottom: -20px;
    img{
        width: 28px;
        margin-right: 12px;
    }
}

.contactInfoPanel{
    width: 520px;
    min-height: 320px;
    padding: 60px 60px 42px;
    color: rgba(0,0,0,0.8);

    .contactInfoDetail{
        
        .infoDescription{
            font-size: 18px;
            padding-bottom: 24px;
        }
        .inputs{
            padding-bottom: 20px;
            .moreInfo{
                overflow: hidden;
                transition: height 0.3s linear;
                height: 0;
                &.show{
                    height: auto;
                }
            }
        }
        .btns{
            padding-top: 20px;
            .buttonSkip{
                margin-top: 16px;
                height: 56px;
                transition: height 0.3s linear;
                overflow: hidden;
                &.hide{
                    height: 0;
                }
            }
        }
    }
}
@media screen and (max-width: 480px){
    .contactInfoPanel{
        width: 100%;
        padding: 48px 24px 32px;
    }
}
