.guestNumberPage{
    height: 100%;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .filterBlurBg{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 0 25px;
        background-color: rgba(0,0,0,0.05);
        backdrop-filter: blur(4px);
    }
    .nameHeader{
        font-weight: 700;
        font-size: 25px;
        line-height: 34px;
        text-align: center;
        letter-spacing: -0.02em;        
        color: rgba(255, 255, 255, 0.8);
        text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
        margin-bottom: 57px;
    }
    .guestNumberOut{
        position: relative;
        width: 100%;
        max-width: 340px;
        padding: 48px 24px 24px;
        margin: 0 auto;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        color: #BDBDBD;
    }
    .storeLogo{
        position: absolute;
        left: 50%;
        top: -39px;
        margin-left: -39px;
        width: 78px;
        height: 78px;
        border-radius: 50%;
    }
    .tableName{
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #BDBDBD;
        b{
            font-weight: 700;
            font-size: 40px;
            line-height: 54px;
            text-align: center;
            letter-spacing: -0.02em;
            color: var(--theme-color);

        }
    }
    .selectNumberOut{
        min-height: 210px;
        .title{
            margin: 16px 0;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.02em;            
            color: #333333;
            span{
                float: left;
                width: 16px;
                height: 16px;
                background: var(--theme-color);
                border-radius: 16px;
                margin-right: -8px;
                margin-top: 3px;
            }
        }
        .inputBox{
            position: relative;
        }
        .orderCodedTips{
            margin-top: 8px;
            font-size: 12px;
            color: #F44336;
        }
        ul{
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 12px);
        }
        ul:after{
            display: block;
            content: "";
            clear: both;
        }
        li{            
            list-style: none;
        }
        .numberBtn{
            width: 25%;
            text-align: center;
            font-size: 18px;
            color: #333333;
            cursor: pointer;
            .numberBtnInner{      
                height: 60px;   
                margin: 8px 12px 8px 0;
                border: 1px solid #BDBDBD;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
            }
            input{
                display: block;
                width: 100%;
                height: 40px;
                line-height: 40px;
                padding: 0;
                font-size: 18px;
                color: #333333;
                margin: 0;
                border: none;
                text-align: center;
                border-radius: 4px;
                background: none;  
                &:focus{
                    outline: none;
                }
            }
            .placeholder{
                position: absolute;
                left: 2px;
                top: 0;
                width: calc(100% - 4px);
                height: 58px;  
                line-height: 50px;
                text-align: center;
                background: none;
                border-radius: 4px;
                background: #fff;
            }
            .selected{
                border: 2px solid var(--theme-color);;
                color: var(--theme-color);;
            }
        }
        .guestNumberTips{
            padding: 2px 8px;
            background: rgba(244, 67, 54, 0.05);
            border-radius: 5px;
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 16px;
            color: #F44336;

        }
    }
    .startOrderBx{
        .disabledButton{
            background: #E0E0E0; 
            color: #fff;
        }
        .startOrderBtn{
            border-radius: 42px;
            margin-top: 10px;
        }

    }
}